import aboutImg from '../assets/images/about.png';

const AboutSection = () => {
  return (
    <section className="about-section" id="about">
      <div className="container">
        <div className="about__block flex align-center space-between">
          <img className="about__image" src={aboutImg} alt="about-crystals" />

          <div className="about__text-container">
            <h3 className="about__text-title inter-600">

                Про компанію

            </h3>
            <p className="about__text inter-300">

              Ми молода команда і заряджені на досягання неймовірних результатів.
Фокусуємось лише на результаті і завжди відкриті до інновацій та пропозицій.
                Ми в пошуку цілеспрямованих і вмотивованих людей. І ми маємо надію, що з тобою ми матимемо perfect match!

              </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default AboutSection
