const Button = ({
  onClick,
  children,
}) => {
  return (
    <button className="button" type="button" onClick={onClick}>
      {children}
    </button>
  )
}

export default Button
