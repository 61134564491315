
import secondImg from '../assets/images/u_rocket.svg';
import firstImg from '../assets/images/u_suitcase.svg';
import thirdImg from '../assets/images/Vector.svg';

const features = [
  {
    id: 1,
    icon: firstImg,
    title: 'Віддалена робота',
    description: 'Ми пропонуємо вам віддалену роботу з гнучким графіком. Ви маєте свободу планувати свій день так, як вам зручно.',
  },
   {
    id: 2,
    icon: secondImg,
    title: 'Карʼєрне зростання',
    description: 'У нашій компанії ви можете очікувати швидкого карʼєрного зростання, оскільки ми є динамічно розвиваючоюся організацією.',
  },
    {
    id: 3,
    icon: thirdImg,
    title: 'Своєчасні виплати заробітної плати.',
    description: '3 нами не було жодного випадку затримки зарплати. Співпраця з нами гарантує вам стабільний щомісячний дохід.',
  }
]

const FeaturesSecion = () => {
  return (
    <section className="feature-section">
      <div className="container">
        <h2 className="feature-title inter-400">Переваги роботи з нами</h2>

        <div className="feature-items flex space-between">
          {features.map(feature => (
            <div className="feature flex align-start" key={feature.id}>
              <div className="feature-icon">
                <img src={feature.icon} alt={feature.icon} />
              </div>

              <div className="feature-text">
                <h3 className="feature-text__title inter-500">
                  {feature.title}
                </h3>

                <h4 className="feature-text__description inter-400">
                  {feature.description}
                </h4>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default FeaturesSecion;
