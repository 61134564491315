import Button from "./Button"

const TitleSection = ({toggleModal}) => {
  return (
    <section className="title-section">
      <div className="container">
        <h1 className="istok-web-bold animate__animated animate__fadeInLeft">Шукаєш роботу?</h1>

        <h2 className="inria-serif-regular animate__animated animate__fadeInLeft">
          Ми пропонуємо простий шлях для початку абсолютного нового етапу Вашої карʼєри
        </h2>

        <Button className="istok-web-regular" onClick={toggleModal}>
         <span className="animate__animated animate__fadeIn">Залишити заявку</span>
        </Button>
      </div>
    </section>
  )
}

export default TitleSection
