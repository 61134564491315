import copyright from '../assets/images/copyright.svg';
import instaImg from '../assets/images/insta.svg';
import tgImg from '../assets/images/tg.svg';


const Footer = () => {
  return (
    <footer className="footer inter-400" id="contacts">
      <div className="container">
        <div className="footer-container flex space-between">
          <div className="city-container">
            <span className="city">Одеса,Україна</span>
          </div>

          <div className="contacts-container">
            <div className="contacts">
              <div className="contacts__phone">
                <span className="contacts__title">Телефон:</span>

                <ul className="contacts__list">
                  <li className="contacts__list-item">
                    <a href="tel:+380735756860" target='_blank' rel="noreferrer">+380735756860</a>
                  </li>
                </ul>
              </div>

              <div className="contacts__email">
                <span className="contacts__title">Email:</span>

                <ul className="contacts__list">
                  <li className="contacts__list-item">
                    <a href="mailto:crystalworkua@gmail.com" target='_blank' rel="noreferrer">crystalworkua@gmail.com</a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="social-container">
<a href="https://www.instagram.com/crystal_company.ua/profilecard/?igsh=N2kwN2ppdmRrY3hu" target='_blank' className="social flex" rel="noreferrer">
              <span className="social-item" rel="noreferrer">
                <img className='insta' src={instaImg} alt="insta" />
              </span>
              </a>
              <a href="https://t.me/crystalcompanyua" target='_blank' className="social flex" rel="noreferrer">
              <span className="social-item" rel="noreferrer">
                <img className='tg' src={tgImg} alt="tg" />
              </span>
          </a>

            </div>


          </div>
        </div>
      </div>

      <div className="copyright">
        <img src={copyright} alt="copyright" />
        2020-2024
      </div>
    </footer>
  )
}

export default Footer
