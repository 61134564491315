import logo from '../assets/images/logo.svg'
import Menu from './Menu'

const Header = () => {
  return (
    <header className="header">
      <div className="container">
        <a href="/" className="header__logo">
        <img src={logo} alt="crystals" className="header__icon animate__bounceIn animate__animated" />
      </a>

      <Menu/>
      </div>
    </header>
  )
}

export default Header
