
const Menu = () => {

  const handleScroll = (event, id) => {
    event.preventDefault(); // Prevent default anchor behavior
    const targetElement = document.getElementById(id); // Find the element by id
    if (targetElement) {
      targetElement.scrollIntoView({
        behavior: 'smooth', // Smooth scrolling
        block: 'start' // Scroll to the top of the element
      });
    }
  };

  return (
    <ul className="menu">
      <li className="menu__item animate__animated animate__bounceInDown">
          <a href="#about" onClick={(e) => handleScroll(e, 'about')} className="menu__link">Про компанію</a>
        </li>
      <li className="menu__item animate__animated animate__bounceInDown">
          <a href="#vacancies" onClick={(e) => handleScroll(e, 'vacancies')} className="menu__link">Вакансії</a>
        </li>
      <li className="menu__item animate__animated animate__bounceInDown">
          <a href="#contacts" onClick={(e) => handleScroll(e, 'contacts')} className="menu__link">Контакти</a>
        </li>
  </ul>
  )
}

export default Menu
