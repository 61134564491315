import IsInViewport from './IsInViewport';

const vacancies = [
  {
    id: 1,
    title: 'МЕНЕДЖЕР',
    subheading: 'ВИМОГИ:',
     subheadingList: [
      'Прагнення працювати на результат і бажання заробляти',
      'Вік від 18',
      ],
      textTitle: 'ЩО МИ ПРОПОНУЄМО:',
      textList: [
      'Виплати від 14000 + % та бонуси',
      'Виплати 2 рази на місяць',
      'Сучасний та комфортний офіс з генератором',
      'Можливість працювати віддалено'
    ]
  },
   {
    id: 2,
    title: 'АДМІНІСТРАТОР',
    subheading: 'ОБОВʼЯЗКИ:',
     text: '1234',
     subheadingList: [
      'Курування роботи співробітників',
      ],
      textTitle: 'ЩО МИ ПРОПОНУЄМО:',
      textList: [
      'Зручний графік роботи 5-ка',
      'Виплати 2 рази на місяць',
      'Ставка 12000 грн + % від виконаної роботи.',
      ]
  },
    {
    id: 3,
    title: 'РЕКРУТЕР',
    subheading: 'ВИМОГИ:',
    subheadingList: [
      'Вільно користуєшся ПК (Word, Excel)',
      'Маєш досвід роботи рекрутером'
      ],
      textTitle: 'ЩО МИ ПРОПОНУЄМО:',
      textList: [
      'Гнучкий графік роботи',
      'Підтримку в адаптації та професійному розвитку',
      'Можливість реалізуватися',
      'Ставка 12000 грн + % від виконаної роботи.',
    ]
  },
]

const VacancyCard = ({
  title,
  subheading,
  textTitle,
  subheadingList,
  textList,
}) => {
  return (
    <div className="vacancy-card__container" id="vacancies">
      <div className="vacancy-card__name-container">
        <h3 className="vacancy-card__name inter-500">
          <IsInViewport classNameWhenVisible='animate__animated animate__bounce'>
            {title}
            </IsInViewport>
        </h3>
      </div>

      <div className="vacancy-card">
        <span className="vacancy-card__subheading inter-500">
          <IsInViewport classNameWhenVisible='animate__animated animate__fadeIn'>
            {subheading}
            </IsInViewport>
        </span>

        <ul className="vacancy-list inter-400">
          {subheadingList.map((item, index) => (
            <li key={index}>
              <IsInViewport classNameWhenVisible='animate__animated animate__fadeIn'>
                {item}
                </IsInViewport>
            </li>
          ))}
        </ul>

        <span className="vacancy-card__subheading inter-500">
          <IsInViewport classNameWhenVisible='animate__animated animate__fadeIn'>
            {textTitle}
            </IsInViewport>
        </span>

        <ul className="vacancy-list inter-400 mb-0">
          {textList.map((item, index) => (
            <li key={index}>
              <IsInViewport classNameWhenVisible='animate__animated animate__fadeIn'>
                {item}
                </IsInViewport>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

const VacanciesSection = () => {
  return (
    <section className="vacancies-section">
      <div className="container">
        <IsInViewport classNameWhenVisible='animate__animated animate__fadeIn'>
          <h2 className="vacancies-title inter-500">Відкриті вакансії:</h2>
        </IsInViewport>


        <div className="vacancies-cards flex space-between">
          {vacancies.map((vacancy) => (
            <VacancyCard key={vacancy.id} {...vacancy} />
          ))}
        </div>
      </div>
    </section>
  )
}

export default VacanciesSection;
