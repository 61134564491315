import React, { useState } from "react";

const SendForm = ({
  isOpen,
  toggleModal,
  onSubmit,
}) => {
  const [formData, setFormData] = useState({
    firstName: '',
    phone: '',
  });

  const toggle = () => {
    toggleModal()
  }

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    fetch('sendForm.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    })
    .then(response => response.text())
    .then(data => {
      console.log('Success:', data);
      toggleModal();
      onSubmit();
    })
    .catch((error) => {
      console.error('Error:', error);
    });
  };

  return (
    <>
      {isOpen && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={toggle}>
              &times;
            </span>
            <h2>Завопнити заявку</h2>
            <form onSubmit={handleSubmit}>
              <label>
                Імʼя:
                <input
                  type="text"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  required
                />
              </label>
              <label>
                Телефон:
                <input
                  type="text"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  required
                />
              </label>
              <button type="submit" className="submit-btn">
                Надіслати
              </button>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default SendForm;
