import { useEffect, useState } from 'react';
import './App.css';
import AboutSection from './components/AboutSection';
import FeaturesSecion from './components/FeaturesSection';
import FeedbackSection from './components/FeedbackSection';
import Footer from './components/Footer';
import Header from './components/Header';
import SendForm from './components/SendForm';
import TitleSection from './components/TitleSection';
import VacanciesSection from './components/VacanciesSection';

function addQueryParam(url, key, value) {
  let separator = url.includes('?') ? '&' : '?';
  return url + separator + encodeURIComponent(key) + '=' + encodeURIComponent(value);
}

function App() {
  const [isOpen, setIsOpen] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const updateFontSize = () => {
    const baseWidth = 1920;
    const baseFontSize = 16;

    const currentWidth = window.innerWidth;

    if (currentWidth < 968) {
      return;
    }

    const newFontSize = (currentWidth / baseWidth) * baseFontSize;

    document.documentElement.style.fontSize = `${newFontSize}px`;
  };

  useEffect(() => {
    updateFontSize();
    window.addEventListener("resize", updateFontSize);

    return () => window.removeEventListener("resize", updateFontSize);
  }, []);

  const onSubmit = () => {
    setShowSuccess(true);
    let url = new URL(window.location.href);

    url.searchParams.append('isSuccess', 'true');

    window.history.replaceState(null, null, url.toString());
  }

  return (
    <div className="App">
      <Header />

      <TitleSection toggleModal={toggleModal} />

      <VacanciesSection />

      <AboutSection />

      <FeaturesSecion />

      <FeedbackSection />

      <SendForm isOpen={isOpen} toggleModal={toggleModal} onSubmit={onSubmit} />

      {showSuccess && (
        <div className="modal modal-success">
          <div className="modal-content">
          <h2>
            Дякуємо за вашу заявку!
          </h2>
          <p>
            Ми зв'яжемося з вами найближчим часом.
          </p>

          <button onClick={() => setShowSuccess(false)}>Готово</button>
          </div>

        </div>
      )}

      <Footer />
    </div>
  );
}

export default App;
