
import Slider from "react-slick";
import 'swiper/css/autoplay';
import 'swiper/css/bundle';
import 'swiper/css/pagination';
import feed1 from '../assets/images/feed1.png';
import feed2 from '../assets/images/feed2.png';
import feed3 from '../assets/images/feed3.png';

const FeedbackSection = () => {
    const settings = {
     dots: true,
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "15px",
    slidesToShow: window.innerWidth < 768 ? 1 : 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1000,
  };

  return (
    <section className="feedback-section">
      <div className="feedback-container container">
        <h2 className="feedback-title">
          Відгуки співробітників
        </h2>

        <div className="slider-container">
      <Slider {...settings}>
        <div className="slider-item">
                <div className="slider-icon">
                  <img src={feed1} alt="slider-icon" />
                </div>
                <div className="slider-title">
                    Даріна Васіліївна
                  </div>

                  <div className="slider-description">
                    Поки дитина спить ,я працюю)
                    У декреті я зрозуміла, що не хочу повертатись на попередню роботу.
                    Мені трапилась реклама і я вирішила спробувати вакансію менеджер. Поки дитина спала — я працювала.
                    Дуже сподобалась доступна подача інформації. Дякую!
                  </div>
              </div>
          <div className="slider-item">
              <div className="slider-icon">
                <img src={feed2} alt="slider-icon" />
                </div>
                <div className="slider-title">
                  Екатеріна Ткачук
                </div>

                <div className="slider-description">
                  Мені подобається, що керівництво завжди готове піти на зустріч. Я студентка,працюю з гнучким графіком і отримую регулярну щотижневу виплату.
                  Подобається адміністратор, дуже розважлива і розуміюча. Є з чим порівнювати)Коли багато часу проводиш на роботі, важливо, щоб колектив був хорошим, а начальство розсудливим. Тому порада: тут у вас все буде добре)
                </div>
            </div>
         <div className="slider-item">
              <div className="slider-icon">
                <img src={feed3} alt="slider-icon" />


                </div>
                <div className="slider-title">
                  Раіса Клінтевіч
                </div>

                <div className="slider-description">
                  Що сказати, це робота, в якій працюєш із задоволенням.
                  Усе починається з привітних адміністраторів, які зустрічають із кавою та посмішкою. В офісі завжди є цукерки, чай, кавоварка, а також порядок, чистота і затишок. Своєчасна командна робота, виплата вчасно - це дуже круто!
                </div>
              </div>
      </Slider>
    </div>
      </div>
    </section>
  )
}

export default FeedbackSection;
